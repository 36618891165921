import { BarChart, ChatBubbleOutline, DynamicFeed, LineStyle, MailOutline, PermIdentity, Report, Storefront, Timeline, WorkOutline } from '@material-ui/icons';
import React from 'react'
import { Link } from 'react-router-dom';
import './dashbar.css';

const Dashbar = () => {
    return (
       <div className="dashbar">
           <div className="dashbarWrapper">
                <div className="dashbarMenu">
                    <h3 className="dashbarTitle">Dashboard</h3>
                    <ul className="dashbarList">
                      <Link to = "/dashboard" className="link">
                        <li className="dashbarListItem">
                            <LineStyle className="dashbarIcon"/>
                            Home
                        </li>
                      </Link>
                        <li className="dashbarListItem">
                            <Timeline className="dashbarIcon"/>
                            Analytics
                        </li>
                    </ul>
                </div>
                <div className="dashbarMenu">
                    <h3 className="dashbarTitle">Quick Menu</h3>
                    <ul className="dashbarList">
                      <Link to = "/users" className="link">
                        <li className="dashbarListItem">
                            <PermIdentity className="dashbarIcon"/>
                            Users
                        </li>
                      </Link>
                      <Link to = "/hadithlists" className="link">
                        <li className="dashbarListItem">
                            <Storefront className="dashbarIcon"/>
                            Hadiths
                        </li>
                      </Link>
                      <Link to = "/collections" className="link">
                        <li className="dashbarListItem">
                            <DynamicFeed className="dashbarIcon"/>
                            Collections
                        </li>
                      </Link>
                      <Link to = "/categories" className="link">
                        <li className="dashbarListItem">
                            <BarChart className="dashbarIcon"/>
                            Categories
                        </li>
                      </Link>
                    </ul>
                </div>
                <div className="dashbarMenu">
                    <h3 className="dashbarTitle">Notification</h3>
                    <ul className="dashbarList">
                        <li className="dashbarListItem">
                            <MailOutline className="dashbarIcon"/>
                            Mail
                        </li>
                        <li className="dashbarListItem">
                            <DynamicFeed className="dashbarIcon"/>
                            Feedback
                        </li>
                        <li className="dashbarListItem">
                            <ChatBubbleOutline className="dashbarIcon"/>
                            Message
                        </li>
                    </ul>
                </div>
                <div className="dashbarMenu">
                    <h3 className="dashbarTitle">Staff</h3>
                    <ul className="dashbarList">
                        <li className="dashbarListItem">
                            <WorkOutline className="dashbarIcon"/>
                            Manage
                        </li>
                        <li className="dashbarListItem">
                            <Timeline className="dashbarIcon"/>
                            Analytics
                        </li>
                        <li className="dashbarListItem">
                            <Report className="dashbarIcon"/>
                            Reports
                        </li>
                        
                    </ul>
                </div>
           </div>
       </div>
    )
}

export default Dashbar
