import React from 'react';
import { FeaturedItem } from '../utils';
import './featuredInfo.css';

const FeaturedInfo = () => {
    return (
        <div className="featured">
            <FeaturedItem type='Hadiths' label='Hadiths' />
            <FeaturedItem type='Collections' label='Collections' />
            <FeaturedItem type='Categories' label='Categories' />
            <FeaturedItem type='Users' label='Users' />
        </div>
    )
}

export default FeaturedInfo
