import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getDocuments } from "../components/utils";
import { CategoryContext } from "./categoryContext";
import { CollectionContext } from "./collectionContext";
import constant from "../config/constant";

export const HadithContext = createContext();
let API_URL = `${constant.API_BASE_URL}/api/hadiths/`;

const HadithContextProvider = (props) => {
  const localData = localStorage.getItem("selectedHadith")
    ? ""
    : JSON.parse(localStorage.getItem("selectedHadith"));
  const [hadithData, setHadithData] = useState([]);
  const [totalHadiths, setTotalHadiths] = useState("");
  const [selectedHadith, setSelectedHadith] = useState(localData);

  const { selectedCollection } = useContext(CollectionContext);
  const { selectedCategory } = useContext(CategoryContext);

  const addHadith = async (data) => {
    console.log(data);
    let url = API_URL + "add";
    try {
      let newcol = await axios.post(url, data, {
        withCredentials: true,
        credentials: "include",
      });

      console.log(newcol);
    } catch (error) {
      console.log(error);
    }
  };

  const getRandomHadith = async () => {
    let url = API_URL + "random";
    try {
      let res = await axios.get(url, {});

      return res.data.hadith;
    } catch (error) {
      console.log(error);
    }
  };

  const getRandomHadith2 = async () => {
    let url = `http://localhost:5002/api/youtube/get-data`;
    try {
      let res = await axios.get(url, {});

      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const postRandomHadith2 = async (data) => {
    let url = `http://localhost:5002/api/youtube/post-by-data`;
    try {
      let res = await axios.post(url, data);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteHadith = (id) => {};

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    //console.log("Effect ran")
    let url =
      API_URL +
      `?category=${selectedCategory}&collection=${selectedCollection}`;
    getDocuments(cancelToken, setHadithData, url, "hadiths");

    return () => {
      cancelToken.cancel();
    };
  }, [selectedCategory, selectedCollection]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    //console.log("Effect ran")
    let url = API_URL;
    getDocuments(cancelToken, setTotalHadiths, url, "total");

    return () => {
      cancelToken.cancel();
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedHadith", JSON.stringify(selectedHadith));
  }, [selectedHadith]);

  return (
    <HadithContext.Provider
      value={{
        hadithData,
        totalHadiths,
        selectedHadith,
        setSelectedHadith,
        addHadith,
        deleteHadith,
        getRandomHadith,
        getRandomHadith2,
        postRandomHadith2
      }}
    >
      {props.children}
    </HadithContext.Provider>
  );
};

export default HadithContextProvider;
