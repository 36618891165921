//import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
//import { db } from '../../firebase';

import './widgetLg.css';

const WidgetLg = () => {
    const [data1] = useState([])
   
    useEffect(() => {
        /*
        const unsub = onSnapshot(query(collection(db, 'hadiths'),orderBy('Created_At','desc')), (snapShot) =>{
            let list = []
            try {
                snapShot.forEach((doc) => {
                    list.push({id: doc.id, ...doc.data()})
                 })
                 setData1(list)
             } catch (error) {
                 console.log(error);
             }
            })
           return () => {
             unsub()
           }*/
         }, []);
 // const newData = data1.slice(0, 5)
  
    const Button =({type})=>{
        return <button className={"widgetLgButton " + type}>{type}</button>;
    };
    return (
        <div className="widgetLg">
            <h3 className="widgetLgTitle">Latest Hadiths Added</h3>
            <table className="widgetLgTable">
                <thead>
                <tr className="widgetLgTr">
                    <th className="widgetLgTh">Hadith No</th>
                    <th className="widgetLgTh">Category</th>
                    <th className="widgetLgTh">Collection</th>
                    <th className="widgetLgTh">Date</th>
                    <th className="widgetLgTh">Status</th>
                </tr>

                </thead>
                <tbody>
                {data1.map((data)=>
                <tr className="widgetLgTr" key={data._id} >
                    <td className="widgetLgUser" data-label='Hadith No'>{data.Hadith_number}</td>
                    <td className="widgetLgCollection" data-label='Category'>{data.Chapter_English}</td>
                    <td className="widgetLgCollection" data-label='Collection'>{data.Collection}</td>
                    <td className="widgetLgDate" data-label='Date'>{moment(data.Created_At.toDate().toString()).format('MMM Do YY')}</td>
                    <td className="widgetLgStatus" data-label='Status'><Button type={data.Status}/></td>
                </tr>
                    )} 
                
                </tbody>
                
            </table>
        </div>
    )
}

export default WidgetLg
