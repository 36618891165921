import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import React, { useEffect, useState } from 'react'
import axios from "axios";

import constant from '../config/constant';

export const CollectionList = ({id, title, book, active, setSelected}) =>{
    return (
        <div className={active ? "collection__card active" : "collection__card"} key = {id} onClick={() => setSelected(title)}>
        <div className="collection__title">{title}</div>
            <span className='collection__book'>{book}</span>
        </div>
    )
}

export const Select = ({ id, dbName, handleInput, showErr }) =>{
    //load collection here and map it to the option
    const [data, setData] = useState([])
    useEffect(() => {
           const cancelToken = axios.CancelToken.source()

           let url=`${constant.API_BASE_URL}/api/${dbName}`
           getDocuments(cancelToken, setData, url, dbName)
                 return () => {
                   cancelToken.cancel()
                  }
         }, [dbName]);
   
    return (
        <>
        <select className="newCategorySelect"onChange={handleInput} name={id} id={id}>
            
            <option value=''> </option>
            {dbName === 'collections'? // for collections
            data.map((opt)=>
            <option key={opt._id} value={opt.Collection}>{opt.Collection} </option>
            )
            ://for Categories
            data.map((opt)=>
                <option key={opt._id} value={opt.Chapter_English}>{opt.Chapter_English} </option>
            )}
        </select>
        {!showErr ? <></> : 
        data.length<1 && <span id= 'error' className="loginError" >Please create new {dbName}</span>}
        </>
    )
}
export const SelectCategory = ({id, dbName, handleInput, colInput }) =>{
    //load collection here and map it to the option
    const [data, setData] = useState([])
    useEffect(() => {
           const cancelToken = axios.CancelToken.source()

           let url=`${constant.API_BASE_URL}/api/${dbName}?collection=${colInput}`
           getDocuments(cancelToken, setData, url, dbName)
                 return () => {
                   cancelToken.cancel()
                  }
         }, [dbName, colInput]);
    //console.log(data)
   
    return (
        <>
        <select className="newCategorySelect"onChange={handleInput} name={id} id={id}>
            {colInput ?//if collection is selected
            <>
            <option value=''> </option>
            {data.map((opt)=>
                <option key={opt._id} value={opt.Chapter_English}>{opt.Chapter_English} </option>
                )}
            </>
            :
                <option value=''> </option>
            }
        </select>
        {!colInput ? 
        <span id= 'error' className="loginError" >Select collection first</span>
        :
        data.length < 1 && 
        <span id= 'error' className="loginError" >Create new {dbName} for {colInput}</span>
         }
        </>
    )
}

export const SelectAuthenticity = ({id, handleInput }) =>{
    return (
        <select className="newCategorySelect"onChange={handleInput} name={id} id={id}>
            <option value=''> </option>
            <option value="Sahih - Authentic">Sahih</option>
            <option value="Hasan - Good">Hasan</option>
            <option value="Da'if - Weak">Daif</option>
        </select>
    )
}
export const SelectLimit = ({id, setLimit }) =>{
    return (
        <select className="newCategorySelect" 
        onChange={e => setLimit(e.target.value)} name={id} id={id}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
        </select>
    )
}

export const FeaturedItem = ({type, label }) => {
       let amount = 0;
       let data = 0;
       let diff = 0;
    return (
        <div className="featuredItem">
        <span className="featuredTitle">{label}</span>
        <div className="featuredMoneyContainer">
            <span className="featuredMoney">{amount}</span>
            <span className="featuredMoneyRate">{diff} % {diff < 0 ? <ArrowDownward className="featuredIcon negative"/> : <ArrowUpward className="featuredIcon"/> }</span>
            <span className="featuredMoneyTotal">{data} total</span>
        </div>
        <span className="featuredSub">Compared to last Month</span>
    </div>
    )
}
export const getDocuments = async (cancelToken, setDocument, url, type, setDocument2) => {
    try {
      let newcol= await axios.get(url, {cancelToken: cancelToken.token})
        if(type=== 'collections') {
            setDocument(newcol.data.collections)
        }
        else if(type=== 'hadiths') {
            setDocument(newcol.data.hadiths)
        }
        else if(type=== 'categories') {
          //  console.log(newcol.data)
            setDocument(newcol.data.categories)
        }
        else if(type=== 'search') {
            setDocument(newcol.data.hadiths)
            setDocument2(newcol.data)
        }
        else if(type=== 'total') {
            setDocument(newcol.data.total)
        }
    } catch (error) {
    //  console.error(error)
      if(axios.isCancel(error)){
        console.log('cancel request')
      }else{
        console.log(error);
      }
    }
  }

export const addDocument = async ( url, type, data) => {
    try {
      let newcol= await axios.post(url, data,{
        withCredentials: true,
        credentials: 'include'
})
     if(type=== 'collections') {
     //   setDocument(newcol.data.collections)
    }
    console.log(newcol)
    } catch (error) {
      if(axios.isCancel(error)){
        console.log('cancel request')
      }else{
        console.log(error);
      }
    }
  }
export const deleteDocument = async (cancelToken, setDocument, url, type, id) => {
  
    try {
        let newcol= await axios.delete(url, {cancelToken: cancelToken.token})
       if(type=== 'collections') {
        console.log(newcol)
         // setDocument(newcol.data.collections)
          setDocument(newcol.data.collections.filter((item)=> item.id !== id));
      }
    } catch (error) {
        console.log(error)
    }
  }