import { Visibility } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import './widgetSm.css';
//import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
//import { db } from '../../firebase';
import { Link } from 'react-router-dom';

export default function WidgetSm() {
    const [data1] = useState([])
   
    useEffect(() => {
        /*
        const unsub = onSnapshot(query(collection(db, 'users'),orderBy('Created_At','desc')), (snapShot) =>{
            let list = []
            try {
                snapShot.forEach((doc) => {
                    list.push({id: doc.id, ...doc.data()})
                 })
                 setData1(list)
             } catch (error) {
                 console.log(error);
             }
            })
           return () => {
             unsub()
           }*/
         }, []);
        //const newData = data1.slice(0, 5);
  
    return (
        <div className="widgetSm">
            <span className="widgetSmTitle">
                New Join Members
            </span>
            <ul className="widgetSmList">
                {data1.map((data)=>
                <li className="widgetSmListItems" key={data._id}>
                    <img src={data.img} alt="" className="widgetSmImg"/>
                    <div className="widgetSmUser">
                        <span className="widgetSmUsername">{data.name}</span>
                        <span className="widgetSmUserTitle">{data.profession}</span>
                    </div>
                    <Link to={'/users/'+data.id} className="widgetSmButton">
                            <Visibility className="widgetSmIcon "/>
                            <span className="widgetSmIconText">Display</span>
                    </Link>
                </li>
                    )
                }
            </ul>
        </div>
    )
}
