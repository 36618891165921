import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../components/sidebar/Sidebar';
import Hadiths from '../components/hadiths/Hadiths';
import Header from '../components/header/Header';
import { OpenInNew } from '@material-ui/icons';
import { CollectionContext } from '../context/collectionContext';
import axios from 'axios';
import { CategoryContext } from '../context/categoryContext';
import constant from '../config/constant';

const HadithContent = () => {
  const [querry, setQuerry] = useState('')
  const [active, setActive] = useState(true)
  const [search, setSearch] = useState(false)
  const { selectedCollection} = useContext(CollectionContext)
  const { setSelectedCategory} = useContext(CategoryContext)
  const [obj, setObj] = useState([]);
  const [sort] = useState({ sort: "Hadith_no", order: "desc" });
  const [page, setPage] = useState(1);
  const [hadithsPerPage, setHadithsPerPage] = useState(10)


 const API_URL = `${constant.API_BASE_URL}/api/hadiths/`;

useEffect(() => {
  const getAllHadiths = async () => {
    try {
      
      const url = `${API_URL}?page=${page}&sort=${sort.sort},${sort.order
      }&collection=${selectedCollection.toString()}&search=${querry}&limit=${hadithsPerPage}`;
    
      const { data } = await axios.get(url);
    
      setObj(data);
    } catch (err) {
      console.log(err);
    }
  };
  
  getAllHadiths();
}, [sort, selectedCollection, page, querry, hadithsPerPage]);

useEffect(() => {
  setSelectedCategory("")

}, [setSelectedCategory])

const handleSearch = async(e) => {
  e.preventDefault()
  try {
    setSearch(true);
    
    const url = `${API_URL}?page=${page}&sort=${sort.sort},${sort.order
    }&collection=${selectedCollection.toString()}&search=${querry}&limit=${hadithsPerPage}`;
    
    const { data } = await axios.get(url);
    setObj(data);
  } catch (error) {
    console.log(error)
  }
  
};  

const handleLeaveSearch=() => {
  setQuerry('')
  setSearch(!search);
}   

  return (
    <div className= {querry ? 'querry__container' : 'hadith__container'}>
      <Header setActive={setActive} querry={querry} setQuerry={setQuerry} handleSearch={handleSearch} />
      {/*
    */}
      <div className={querry ? 'querry__wrapper' : 'hadith__wrapper'} onClick={()=> setActive(false)}>
        {!search ?
          <>
            <Sidebar active={active} setActive={setActive}/>
            
            <Hadiths search={search} querry={querry} total={obj.total} handleLeaveSearch={handleLeaveSearch}
            setPage={setPage} page={page} hadithsPerPage={hadithsPerPage}
            setHadithsPerPage={setHadithsPerPage} currentHadith={obj.hadiths}  />
          </>
          :<>
          <Hadiths search={search} querry={querry} total={obj.total} handleLeaveSearch={handleLeaveSearch}
          setPage={setPage} page={page} hadithsPerPage={hadithsPerPage}
          setHadithsPerPage={setHadithsPerPage} currentHadith={obj.hadiths}  />
          </>
        }  
      </div>
        {!querry && 
        <div className="view_sidebar"onClick={()=>setActive(!active)}>
          <OpenInNew />
        </div>
        }
    </div>
  )
}

export default HadithContent