import React from "react";
import Register  from "./pages/Register";
import Login  from "./pages/Login";
import Collections from "./pages/Collections";
import HadithContent from "./pages/HadithContent";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import VideoCollection from "./pages/VideoCollection/VideoCollection";
import AdminLogin from './pages/AdminLogin/AdminLogin';
import AdminLogin2 from "./pages/AdminLogin/AdminLogin2";
//import ProtectedRoute from "./components/ProtectedRoute";



const App = () => {

  return (
      <Router>
        <Routes>
            <Route path="/" exact element={<Collections />}/>
            <Route path="/hadiths/:hadithId" element={<HadithContent />}/>
            <Route path="/login" element={<Login />}/>
            <Route path="/admin-login" element={<AdminLogin />}/>
            <Route path="/admin-login2" element={<AdminLogin2 />}/>
            <Route path="/register" element={<Register />}/>
            <Route path="/dashboard" element={<Home />}/>
            <Route path="/videos" element={<VideoCollection />}/>
        </Routes>
      </Router>
  )
};

export default App;