import axios from "axios";
import React, {createContext, useContext, useEffect, useState} from "react";
import { addDocument, getDocuments } from "../components/utils";
import { CollectionContext } from "./collectionContext";
import constant from "../config/constant";

export const CategoryContext = createContext();;

const CategoryContextProvider = (props) => {
    const localData = localStorage.getItem('selectedCategory') ? "" : JSON.parse(localStorage.getItem('selectedCategory'))
    const [categoryData, setCategoryData] = useState([])
    const [selectedCategory, setSelectedCategory]= useState(localData)


    const addCategory = (data) => {
        let url=`${constant.API_BASE_URL}/api/categorys/add`
        addDocument( url, 'categories', data )
    }
    const deleteCategory = (id) =>{
        
    }
        
    
  const { selectedCollection} = useContext(CollectionContext)
    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        //console.log("Effect ran")
      
        let url=`${constant.API_BASE_URL}/api/categories?collection=${selectedCollection}`
        getDocuments(cancelToken, setCategoryData, url,'categories')
        
        return () => {
        cancelToken.cancel()
        }
    }, [selectedCollection]);
    
    useEffect(() => {
        localStorage.setItem('selectedCategory', JSON.stringify(selectedCategory))
      }, [selectedCategory])
      
    return(
        <CategoryContext.Provider value ={{categoryData, selectedCategory, setSelectedCategory, addCategory, deleteCategory}}>
            {props.children}
        </CategoryContext.Provider>
    );
};

export default CategoryContextProvider;