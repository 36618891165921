import React from 'react';
import Chart from '../../components/chart/Chart';
import FeaturedInfo from '../../components/featuredInfo/FeaturedInfo';
import './home.css';
import WidgetSm from '../../components/widgetSm/WidgetSm';
import WidgetLg from '../../components/widgetLg/WidgetLg';
import Topbar from '../../components/topbar/Topbar';
import { userData } from '../../data/data';
import Dashbar from '../../components/dashbar/Dashbar';

const Home = () => {
    return (
        <div className='homeContainer'>
        <Topbar/>
          <div className="container">
             <Dashbar/>
            <div className="home">
                <FeaturedInfo/>
                <Chart data={userData} type="monotone" grid dataKey="New Hadiths" title="Hadith Analytics"/>
                <div className="homeWidgets">
                    <WidgetSm/>
                    <WidgetLg/>
                </div>
            </div>
         </div>
         </div>
    );
}

export default Home;
