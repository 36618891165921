import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CollectionContext } from '../../context/collectionContext';
import { HadithContext } from '../../context/hadithContext';
import Logo from '../../img/logo.png'
import Hadiths from '../hadiths/Hadiths';
import Header from '../header/Header';
import './selectCollection.css'
import constant from '../../config/constant';

export default function Home() {
    const {collectionData, setSelectedCollection} = useContext(CollectionContext)
    const {totalHadiths} = useContext(HadithContext)
    const [querry, setQuerry] = useState('')
    const [search, setSearch] = useState(false)
    const [obj, setObj] = useState([]);
    const [sort] = useState({ sort: "Hadith_no", order: "desc" });
    const [page, setPage] = useState(1);
    const [hadithsPerPage, setHadithsPerPage] = useState(10)
  
  
   const API_URL = `${constant.API_BASE_URL}/api/hadiths/`;
  
   
  useEffect(() => {
    const getAllHadiths = async () => {
      try {
      // if(search){
        const url = `${API_URL}?page=${page}&sort=${sort.sort},${sort.order
        }&search=${querry}&limit=${hadithsPerPage}`;
      
        const { data } = await axios.get(url);
      
        setObj(data);
      //}
      } catch (err) {
        console.log(err);
      }
    };
    
    getAllHadiths();
  }, [sort, search, page, querry, hadithsPerPage]);


   const handleSearch = async(e) => {
    e.preventDefault()
    try {
      setSearch(true);
      
      const url = `${API_URL}?page=${page}&sort=${sort.sort},${sort.order
      }&search=${querry}&limit=${hadithsPerPage}`;
      
      const { data } = await axios.get(url);
      setObj(data);
    } catch (error) {
      console.log(error)
    }
    
  };  
  
  const handleLeaveSearch=() => {
    setQuerry('')
    setSearch(!search);
  }   

  return (
    <>
    <Header home={true} querry={querry} setQuerry={setQuerry} handleSearch={handleSearch} />
    {!search ?
      <main className="main">
        <div className="description">
          <p>
            Select Hadiths Collection&nbsp;
            <code className="code">of choice</code>
          </p>
           <div>Home of <span className="total">{" "+totalHadiths+" "} </span> authentic Hadiths</div>
          </div>

        <div className="center">
          <div className="thirteen">
          <img
              src={Logo}
              alt="logo"
            />
          </div>
        </div>

        <div className="grid">
        {
            collectionData.map((data) =>(
            <Link
                to={`/hadiths/${data.Collection.replace(' ', '').toLowerCase()}`} key = {data._id} onClick={() => setSelectedCollection(data.Collection)}
                className="card" >
                <h2> {data.Collection} <span>-&gt;</span></h2>
                <p>{data.Author}</p>
            </Link>
        ))}
        </div>
      </main>
    :
      <div className='querry__container'>
        <div className='querry__wrapper'>
          <Hadiths search={search} querry={querry} total={obj.total} handleLeaveSearch={handleLeaveSearch}
          setPage={setPage} page={page} hadithsPerPage={hadithsPerPage}
          setHadithsPerPage={setHadithsPerPage} currentHadith={obj.hadiths}  />
        </div>
      </div>
    }
    </>
  )
}
