import {  ArrowBackIosOutlined, Dashboard, Favorite, Home, Search, SearchRounded, VerifiedUser } from '@material-ui/icons'
import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { CategoryContext } from '../../context/categoryContext'
import { CollectionContext } from '../../context/collectionContext'
import {HadithContext} from '../../context/hadithContext'
import { useUserAuth } from '../../context/UserAuthContext'
import { Select } from '../utils'
import './header.css'

const Header = ({setActive, querry, setQuerry, handleSearch, home }) => {
   const [searchBtn, setSearchBtn] = useState(false)

    const navigate = useNavigate()
    const { logOut, RealUser } = useUserAuth();
    const { setSelectedHadith } = useContext(HadithContext)
    const {setSelectedCollection} = useContext(CollectionContext)
    const {setSelectedCategory} = useContext(CategoryContext)

    const handleLogout = async () => {
        if(RealUser){
          try {
           await logOut();
           navigate("/");
         } catch (error) {
           console.log(error.message);
         }
        }else{
          navigate('/')
        }
      }
      
      const handleInput = (e) =>{
          const value = e.target.value;
          setSelectedCollection(value)
          setSelectedHadith('')
          setSelectedCategory('')
          setActive(true)
          navigate(`/hadiths/${value.replace(' ', '').toLowerCase()}`);
      }
   // console.log(RealUser)
    const newp = RealUser ? RealUser.name?.split(' ')[0]: '';
      
    return (
        <div className="header">
            <div className="headerWrapper">
              {!searchBtn &&
                <div className="headerLeft">
                    <Link to= '/'className="headerLogo">I<i className="icon"><Favorite/></i>Rasul Allah</Link>
                </div>}
                
                <div className={searchBtn ? "headerMiddle1" : 'headerMiddle'}>
                    <form className="headerSearch" onSubmit={handleSearch}>
                        <input className='headerSearhInput' type="text"placeholder='Search here...' value={querry} onChange={e => setQuerry(e.target.value)} />
                        <Search type='button' onClick={handleSearch} className='searchIcon'/>
                    </form>
                </div>
                
                {<div className="headerRight">
                    {!RealUser ?
                    <div className="headerSelect">
                        {!searchBtn ?
                         <SearchRounded onMouseOver={()=> setSearchBtn(!searchBtn)} onClick={()=> setSearchBtn(!searchBtn)}className='searchIconRounded'/>
                         :   
                        <ArrowBackIosOutlined onMouseOver={()=> setSearchBtn(!searchBtn)} onClick={()=> setSearchBtn(!searchBtn)}className='searchIconRounded'/>
                        }
                        {!home && <Select id='collection' dbName='collections' showErr={false} handleInput={handleInput} />}
                    </div>
                    :  
                    <div className="headerSelect">
                        {!searchBtn ?
                         <SearchRounded onMouseOver={()=> setSearchBtn(!searchBtn)} onClick={()=> setSearchBtn(!searchBtn)}className='searchIconRounded'/>
                         :   
                        <ArrowBackIosOutlined onMouseOver={()=> setSearchBtn(!searchBtn)} onClick={()=> setSearchBtn(!searchBtn)}className='searchIconRounded'/>
                        }
                        {!home && <Select id='collection' dbName='collections' showErr={false} handleInput={handleInput} />}
                        <div className='headerDropdown'>
                            {RealUser.image ? <img src={RealUser.image} alt="profile avatar" className="headerAvatar"/>
                            :
                            RealUser.name ?
                            <span className="headerName">{newp}</span> 
                            : 
                            'Admin'}
                            <ul className='dropdownContents'>
                                <li  className="dropdownList">
                                    <Dashboard/>
                                    <Link className="dropdownLink" to="/dashboard">Dashboard</Link>
                                </li>
                                <li  className="dropdownList">
                                    <Home/>
                                    <Link className="dropdownLink" to="/">Select Collection</Link>
                                </li>
                                <li  className="dropdownList"onClick={handleLogout}> 
                                    <VerifiedUser/>
                                    <Link className="dropdownLink"to="#">Sign Out</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    }
                    
                </div>
                }
            </div>
        </div>
    )
}

export default Header
