import axios from "axios";
import React, {createContext, useEffect, useState} from "react";
import { addDocument, getDocuments } from "../components/utils";
import constant from "../config/constant";

export const CollectionContext = createContext();;

const CollectionContextProvider = (props) => {
    const localData = localStorage.getItem('selectedCollection') === null ? "": JSON.parse(localStorage.getItem('selectedCollection'))
    const [collectionData, setCollectionData] = useState([])
    const [selectedCollection, setSelectedCollection]= useState(localData)


    const addCollection = (data) => {
      //  const cancelToken = axios.CancelToken.source()
      //  cancelToken.cancel()
        let url=`${constant.API_BASE_URL}/api/collections/add`
        addDocument( url, 'collections', data )
    }
    const deleteCollection = (id) =>{
        
    }
        
    useEffect(() => {
        const cancelToken = axios.CancelToken.source()
        //console.log("Effect ran")
      
        let url=`${constant.API_BASE_URL}/api/collections`
        getDocuments(cancelToken, setCollectionData, url,'collections')
        
        return () => {
        cancelToken.cancel()
        }
    }, []);  

    useEffect(() => {
        localStorage.setItem('selectedCollection', JSON.stringify(selectedCollection))
    }, [selectedCollection])


    return(
        <CollectionContext.Provider value ={{collectionData, selectedCollection, setSelectedCollection, addCollection, deleteCollection}}>
            {props.children}
        </CollectionContext.Provider>
    );
};

export default CollectionContextProvider;