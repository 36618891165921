import { OpenInNew, RecordVoiceOver } from '@material-ui/icons'
import React from 'react'
import { useContext } from 'react'
import { CategoryContext } from '../../context/categoryContext'
import { CollectionContext } from '../../context/collectionContext'
import { HadithContext } from '../../context/hadithContext'
import Pagination from '../pagination1'
import './hadiths.css'

const Hadiths = ({search,querry, total, setPage, page, hadithsPerPage, setHadithsPerPage, currentHadith, handleLeaveSearch }) => {
 
  const {hadithData} = useContext(HadithContext)
  const {selectedCategory} = useContext(CategoryContext)
  const {selectedCollection} = useContext(CollectionContext)

  return (
    <div className={querry ? 'hadiths querry_h' : 'hadiths'}>
      {search ?
        <div className="hadiths__wrapper">
          <button className='hadiths__back_btn' onClick={handleLeaveSearch} >Leave Search</button>
        <h1 className="hadiths__category">Searched query <span>{querry}</span></h1>
       <Pagination page={page? page : 1}
        limit={hadithsPerPage ? hadithsPerPage : 10}
        setLimit={setHadithsPerPage}
        total={total? parseInt(total): 0}
        setPage={(page) => setPage(page)}
        />
        {currentHadith.map((data)=>
          <div className="hadiths__bottom" key={data._id}>
            <div className="hadiths__contents">
            <h4 className="">Hadith {data.Hadith_number}</h4>
              <div className="hadiths__properties">
                <span className={data.English_Grade==='Sahih-Authentic'? `hadiths__auth1`: data.English_Grade==='hasan'? `hadiths__auth2`: `hadiths__auth3`}> {data.English_Grade}</span>
                <span className="hadiths__narrater"><RecordVoiceOver/> {data.English_Isnad}</span>
              </div>
              {data.Collection && <span className="hadiths__translatedby">{data.Collection}</span>}
              {data.Arabic_Matn &&
              <div className="hadiths__arabic">
                <p> 
                {data.Arabic_Matn}   
                </p>
              </div>}
              <div className="hadiths__english">
                <p> 
                  {data.English_Matn}
                </p>
              </div>
              {data.Translated && <span className="hadiths__translatedby">Translated by {data.Translated}</span>}
            </div>
          </div>
              )}
        {/*<Pagination hadithPerPage={hadithsPerPage} totalHadiths={newData.length} paginate={paginate} />
      */}
      
        </div>
        :
        
          !selectedCategory ?
            <div className="hadiths__wrapper">
            <div className="hadiths__title">
              <h1>{selectedCollection}</h1>        
            </div>
              The Hadith you choose from the left will appear here. For Mobile, use the icon <OpenInNew/> enable the sidebar menu. 
            </div>
          :
            <div className="hadiths__wrapper">
              <h1 className="hadiths__category">{selectedCategory}</h1>
              {hadithData.length > 0 && 
              <>
                {hadithData.map((data)=>
                  <div className="hadiths__bottom" key={data._id}>
                    <div className="hadiths__contents">
                    <h6 className="">Hadith {data.Hadith_number}</h6>
                      <div className="hadiths__properties">
                        <span className={data.English_Grade.toLowerCase().includes('sahih')? `hadiths__auth1`: data.English_Grade.toLowerCase().includes('hasan')? `hadiths__auth2`: `hadiths__auth3`}> {data.English_Grade}</span>
                        <span className="hadiths__narrater"><RecordVoiceOver className='hadiths__narrater_icon'/> {data.English_Isnad}</span>
                      </div>
                      {data.Arabic_Matn &&
                      <div className="hadiths__arabic">
                        <p> 
                        {data.Arabic_Matn}   
                        </p>
                      </div>}
                      <div className="hadiths__english">
                        <p> 
                          {data.English_Matn}
                        </p>
                      </div>
                      {data.Translated && <span className="hadiths__translatedby">Translated by {data.Translated}</span>}
                    </div>
                  </div>
                )}
              </>
              }
            
            </div>
        
      }
    </div>
  )
}

export default Hadiths