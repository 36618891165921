import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useContext, useEffect, useState } from "react";
import { HadithContext } from "../../context/hadithContext";
import { userAuthContext } from "../../context/UserAuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import CONSTANT from "../../config/constant";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 12,
  },
  heading: {
    fontSize: 32,
    fontWeight: 800,
    width: "100%",
    marginBottom: 12,
    color: theme.palette.common.black,
  },
  description: {
    color: theme.palette.common.black,
  },
  strong: {
    fontWeight: 600,
    color: theme.palette.grey[600],
  },
}));

const VideoCollection = () => {
  const { getRandomHadith2, postRandomHadith2 } = useContext(HadithContext);
  const { getGoogleLoginURL } = useContext(userAuthContext)
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [hadith, setHadith] = useState(null);

  // const PLATFORM = location.state.platform;
  const PLATFORM = 'YouTube';

  useEffect(() => {
    async function fetchRandomHadith() {
      setLoading(true);
      const newHadith = await getRandomHadith2();
      setHadith(newHadith);
      setLoading(false);
      console.log({ newHadith });
    }

    // if (!location.state?.accessToken) {
    //   navigate("/");
    //   alert("Unauthorized");
    //   return;
    // }

    fetchRandomHadith();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.accessToken]);

  async function postHadith() {
    if (!hadith) {
      return;
    }

    if (PLATFORM === "YouTube") {
      await postRandomHadith2(hadith);
    }

    alert("Hadith has been successfuly posted on " + PLATFORM);
  }

  function logout() {
    navigate(PLATFORM === "YouTube" ? "/admin-login2" : "/admin-login", {
      replace: true,
    });
  }

  async function connectToYoutube() {
    window.location.href = `${CONSTANT.API_BASE_URL}/api/google/open`;
  }

  return (
    <Grid container className={clsx(classes.container, "main")}>
      <Box>
        <Box textAlign="right">
          <Button variant="contained" color="primary" onClick={logout}>
            Log Out
          </Button>
        </Box>

        <Typography component="h1" className={classes.heading}>
          Post Hadith Videos
        </Typography>

        {loading && <CircularProgress />}
        {!loading && hadith && (
          <Box>
            <Typography component="strong" className={classes.strong}>
              Hadith: {hadith.Hadith_number}, {hadith.Collection}
            </Typography>
            <Typography className={classes.description}>
              {hadith.English_Hadith}
            </Typography>

            <Box marginTop={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={connectToYoutube}
                style={{ marginRight: 6 }}
              >
                Connect to YouTube
              </Button>
              <Button variant="contained" color="primary" onClick={postHadith}>
                Post Hadith on {PLATFORM}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default VideoCollection;
