import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import CategoryContextProvider from './context/categoryContext';
import CollectionContextProvider from './context/collectionContext';
import HadithContextProvider from './context/hadithContext';
import { UserAuthContextProvider } from './context/UserAuthContext';

ReactDOM.render(
  <React.StrictMode>
    <UserAuthContextProvider>
      <CollectionContextProvider>
        <CategoryContextProvider>
          <HadithContextProvider>
            <App />
          </HadithContextProvider>
        </CategoryContextProvider>
      </CollectionContextProvider>
    </UserAuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);