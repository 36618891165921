import React from "react";
import { Grid, Typography } from "@material-ui/core";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const navigate = useNavigate();

  return (
    <Grid container style={{ padding: 12 }} className="main">
      <Grid item xs={12}>
        <Typography
          component="h1"
          color="textPrimary"
          align="center"
          style={{ fontWeight: 800, fontSize: 24, marginBottom: 24 }}
        >
          Sign In
        </Typography>

        <FacebookLogin
          appId={process.env.REACT_APP_FB_APP_ID}
          style={{
            backgroundColor: '#4267b2',
            color: '#fff',
            fontSize: '16px',
            padding: '12px 24px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
          onSuccess={(response) => {
            console.log("Login Success!", response);
            navigate("/videos", {
              state: {
                accessToken: response.accessToken,
                platform: 'Facebook'
              },
            });
          }}
          onFail={(error) => {
            console.log("Login Failed!", error);
            alert('Failed to login');
          }}
          onProfileSuccess={(response) => {
            console.log("Get Profile Success!", response);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AdminLogin;
