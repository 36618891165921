import {  Dashboard, VerifiedUser, PermIdentity, Storefront, DynamicFeed, BarChartOutlined, Home } from '@material-ui/icons'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useUserAuth } from '../../context/UserAuthContext'
import './topbar.css'

const Topbar = () => {
    
  // const [user, setUser] = useState([])

    const navigate= useNavigate()
    const { logOut, RealUser } = useUserAuth();
    
    const handleLogout = async () => {
        if(RealUser){
          try {
           await logOut();
           navigate("/");
         } catch (error) {
           console.log(error.message);
         }
        }else{
          navigate('/')
        }
      }


    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <Link to='/dashboard' className="topLeft">
                    <span className="topLogo">Admin Panel</span>
                </Link>
                <div className="topRight">
                    {/*<div className="topbarIconsContainer">
                        <NotificationsNone/>
                        <span className="topIconBadge">2</span>
                    </div>*/}
                    <div className='headerDropdown'>
                        {
                            RealUser.img ?
                             <img src={RealUser.img} alt="profile avatar" className="headerAvatar"/>
                        :
                        RealUser.name ?
                            <span className="headerName">{RealUser.name}</span> 
                        : 
                            'Admin'
                        }
                         <ul className='dropdownContents'>
                            <li  className="dropdownList hidedrop">
                                <Dashboard/>
                                <Link className="dropdownLink" to="/dashboard">Dashboard</Link>
                            </li>
                            <li  className="dropdownList hidedrop">
                                <PermIdentity/>
                                <Link className="dropdownLink" to="/users">Users</Link>
                            </li>
                            <li  className="dropdownList hidedrop">
                                <Storefront/>
                                <Link className="dropdownLink" to="/hadithlists">Hadiths</Link>
                            </li>
                            <li  className="dropdownList hidedrop">
                                <DynamicFeed/>
                                <Link className="dropdownLink" to="/collections">Collections</Link>
                            </li>
                            <li  className="dropdownList hidedrop">
                                <BarChartOutlined/>
                                <Link className="dropdownLink" to="/categories">Categories</Link>
                            </li>
                            <li  className="dropdownList">
                                <Home/>
                                <Link className="dropdownLink" to="/">Go to home</Link>
                            </li>
                            <li  className="dropdownList"onClick={handleLogout}> 
                                <VerifiedUser/>
                                <Link className="dropdownLink"to="#">Sign Out</Link>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Topbar
