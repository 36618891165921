import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import constant from "../config/constant";

export const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [RealUser, setRealUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  let USER_API_URL = `${constant.API_BASE_URL}/api/users/`;
  let GOOGLE_API_URL = `${constant.API_BASE_URL}/api/google/`;

  const loadUser = async () => {
    try {
      let logUser = await axios.get(USER_API_URL + "me", {
        withCredentials: true,
        credentials: "include",
      });
      return logUser;
    } catch (error) {
      console.error(error);
    }
  };

  const getGoogleLoginURL = async () => {
    let url = GOOGLE_API_URL + "open";
    try {
      let res = await axios.get(url, {});

      return res.data.url;
    } catch (error) {
      console.log(error);
    }
  };

  const logIn = async (email, password) => {
    try {
      let logUser = await axios.post(
        USER_API_URL + "login",
        { email, password },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      if (logUser.data.name) {
        localStorage.setItem("user", JSON.stringify(logUser.data));
      }
      setRealUser(logUser.data);

      loadUser();
      return logUser;
    } catch (error) {
      console.error(error);
    }
  };
  const signUp = async (name, email, password, image, profession) => {
    try {
      let regUser = await axios.post(USER_API_URL + "register", {
        name,
        email,
        password,
        image,
        profession,
      });
      //  console.log(regUser)
      if (regUser.data.name) {
        localStorage.setItem("user", JSON.stringify(regUser.data));
      }
      setRealUser(regUser.data);

      return regUser;
    } catch (error) {
      console.error(error);
    }
  };

  const logOut = async () => {
    localStorage.removeItem("user");
    return await axios.post(USER_API_URL + "logout").then((response) => {
      return response.data;
    });
  };

  useEffect(() => {
    const unsubscribe = () => {
      return JSON.parse(localStorage.getItem("user"));
    };

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider value={{ RealUser, logIn, signUp, logOut, getGoogleLoginURL }}>
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
