import React from 'react'
import SelectCollection from '../components/selectCollection/SelectCollection'


const Collections = () => {
  return (
  <>
      <SelectCollection/>
  </>
  )
}

export default Collections