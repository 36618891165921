import './sidebar.css';
import { useContext } from "react";
import { CategoryContext } from '../../context/categoryContext';

export default function Sidebar({active, setActive}){
 
  const { categoryData, setSelectedCategory} = useContext(CategoryContext)
     
     return (
        <div className={active?'active-sidebar':'sidebar'}>
          <div className="sidebar-content">
              { categoryData.map((item, index) => 
                  <div className="sidebar-item plain" key={index} onClick={()=>setSelectedCategory(item.Chapter_English)}>
                      <div onClick={() => setActive(!active)}>{item.Chapter_English}</div>
                  </div>
              )}
          </div>
        </div>
    )
}