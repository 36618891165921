import React from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const AdminLogin2 = () => {
  const navigate = useNavigate();

  const username = React.useRef("");
  const password = React.useRef("");

  const login = (e) => {
    e.preventDefault();
    const usernameValue = username.current.value;
    const passwordValue = password.current.value;

    if (!usernameValue || !passwordValue) {
      alert("Invalid username or password!");
      return;
    }

    if (usernameValue !== "admin" && passwordValue !== "inzilo") {
      alert("Invalid username or password!");
      return;
    }

    navigate("/videos", {
      state: {
        accessToken: "test",
        platform: "YouTube",
      },
    });
  };

  return (
    <Grid container style={{ padding: 12 }} className="main">
      <Grid item xs={12}>
        <Typography
          component="h1"
          color="textPrimary"
          align="center"
          style={{ fontWeight: 800, fontSize: 24, marginBottom: 24 }}
        >
          Sign In
        </Typography>

        <form autoComplete="off" onSubmit={login}>
          <TextField
            inputRef={username}
            fullWidth
            label="Username"
            placeholder="Username"
          />

          <TextField
            inputRef={password}
            type="password"
            fullWidth
            label="Password"
            placeholder="Password"
          />

          <Button
            style={{ marginTop: 16 }}
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
          >
            Login
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default AdminLogin2;
