
  export const formInputUser = [
    {  id: "username",type: 'text', label: 'Username', placeholder: 'jonDoe',},
    {  id: "displayName",type: 'text', label: 'Name', placeholder: 'Jon Doe',},
    {  id: "email",type: 'email', label: 'Email', placeholder: 'johndoe@gmail.com',},
    {  id: "phone",type: 'text', label: 'Phone', placeholder: '+234817289392',},
    {  id: "role",type: 'text', label: 'Role', placeholder: 'admin/ editor',},
    {  id: "password",type: 'password', label: 'Password'},
    {  id: "occupation",type: 'text', label: 'Occupation', placeholder: 'profession',},
    {  id: "address",type: 'text', label: 'Address', placeholder: '24, Jon bull St, Newyork',},
    {  id: "country",type: 'text', label: 'Country', placeholder: 'USA',},
  ];
  export const formInputUserUpdate = [
    {  id: "username",type: 'text', label: 'Username', placeholder: 'jonDoe',},
    {  id: "displayName",type: 'text', label: 'Name', placeholder: 'Jon Doe',},
    {  id: "phone",type: 'text', label: 'Phone', placeholder: '+234817289392',},
    {  id: "role",type: 'text', label: 'Role', placeholder: 'admin/ editor',},
    {  id: "occupation",type: 'text', label: 'Occupation', placeholder: 'profession',},
    {  id: "address",type: 'text', label: 'Address', placeholder: '24, Jon bull St, Newyork',},
    {  id: "country",type: 'text', label: 'Country', placeholder: 'USA',},
];

export const formInputCollection = [
  {  id: "Collection",type: 'text', label: 'Collection Name', placeholder: 'Collection Name',},
  {  id: "Volume",type: 'number', label: 'Volumes', placeholder: '12',},
  {  id: "Hadith_Total",type: 'number', label: 'Total Hadiths', placeholder: 'e.g. 12',},
  {  id: "Categories",type: 'number', label: 'Categories', placeholder: '12',},
  {  id: "Book",type: 'text', label: 'Book Name', placeholder: 'where did you extract the collection from',},
  {  id: "Author",type: 'text', label: 'Book Author', placeholder: 'Name of author',},
  {  id: "Publisher",type: 'text', label: 'Book Publisher', placeholder: 'publisher description',},
  {  id: "DownloadLink",type: 'text', label: 'Source', placeholder: 'Download Link',},
  {  id: "Description",type: 'text', label: 'Collection Description', placeholder: 'Tell us about the collection',},
];
export const formInputCategory = [
    {  id: "Chapter_Number",type: 'number', label: 'Category Number', placeholder: 'e.g. 23',},
    {  id: "Chapter_English",type: 'text', label: 'Category Name', placeholder: 'Category Name',},
    {  id: "Volume",type: 'number', label: 'Volumes', placeholder: 'e.g. 12',},
    {  id: "Hadith_Total",type: 'number', label: 'Total Hadiths', placeholder: 'e.g. 12',},
    {  id: "Collection",type: 'text', label: 'Collection Name'},
];
export const formInputHadith = [
  {  id: "Hadith_number",type: 'number', label: 'Hadith Number', placeholder: 'e.g. 23',},
  {  id: "Volume",type: 'number', label: 'Volumes', placeholder: 'e.g. 12',},
  {  id: "Collection",type: 'text', label: 'Collection Name',},
  {  id: "Chapter_English",type: 'text', label: 'Category'},
  {  id: "English_Grade",type: 'text', label: 'English_Grade'},
  {  id: "English_Isnad",type: 'text', label: 'Narrated By', placeholder: '',},
  {  id: "Translated",type: 'text', label: 'Translated By', placeholder: '',},
  {  id: "Arabic_Matn",type: 'text', label: 'Arabic', placeholder: '',},
  {  id: "English_Matn",type: 'text', label: 'English', placeholder: '',},
];
export const hadithData = [
    { name: 'Jan', "Sales": 4000,},
    { name: 'Feb', "Sales": 3000,},
    { name: 'Mar', "Sales": 7000,},
    { name: 'Apr', "Sales": 2780,},
    { name: 'May', "Sales": 1890,},
  ];

  export const userData = [
    { name: 'Jan', "New Hadiths": 4000,},
    { name: 'Feb', "New Hadiths": 3000,},
    { name: 'Mar', "New Hadiths": 7000,},
    { name: 'Apr', "New Hadiths": 2780,},
    { name: 'May', "New Hadiths": 1890,},
    { name: 'Jun', "New Hadiths": 2390,},
    { name: 'Jul', "New Hadiths": 3490,},
    { name: 'Aug', "New Hadiths": 7890,},
    { name: 'Sep', "New Hadiths": 400, },
    { name: 'Oct', "New Hadiths": 5130,},
    { name: 'Nov', "New Hadiths": 8888,},
    { name: 'Dec', "New Hadiths": 102, },
];
  
 export const userRows = [
    {
        id: 1,
        username: 'Jon Snow',
        avatar: "https://images.pexels.com/photos/1152994/pexels-photos-1152994.jpeg",
        email: "jon@gmail.com",
        Status:"active",
        transaction:"$1456.00",
    },
    {
        id: 2,
        username: 'Lanto Blue',
        avatar: "https://images.pexels.com/photos/1152994/pexels-photos-1152994.jpeg",
        email: "Blue@gmail.com",
        Status:"active",
        transaction:"$7816.00",
    },
    {
        id: 3,
        username: 'El Capino LOP',
        avatar: "https://images.pexels.com/photos/1152994/pexels-photos-1152994.jpeg",
        email: "LOP@gmail.com",
        Status:"active",
        transaction:"$1456.00",
    },
    {
        id: 4,
        username: 'Jon Snow',
        avatar: "https://images.pexels.com/photos/1152994/pexels-photos-1152994.jpeg",
        email: "jon@gmail.com",
        Status:"active",
        transaction:"$1456.00",
    },
    {
        id: 5,
        username: 'Lanto Blue',
        avatar: "https://images.pexels.com/photos/1152994/pexels-photos-1152994.jpeg",
        email: "Blue@gmail.com",
        Status:"active",
        transaction:"$7816.00",
    },
    {
        id: 6,
        username: 'El Capino LOP',
        avatar: "https://images.pexels.com/photos/1152994/pexels-photos-1152994.jpeg",
        email: "LOP@gmail.com",
        Status:"active",
        transaction:"$1456.00",
    },
    {
        id: 7,
        username: 'Jon Snow',
        avatar: "https://images.pexels.com/photos/1152994/pexels-photos-1152994.jpeg",
        email: "jon@gmail.com",
        Status:"active",
        transaction:"$1456.00",
    },
    {
        id: 8,
        username: 'Lanto Blue',
        avatar: "https://images.pexels.com/photos/1152994/pexels-photos-1152994.jpeg",
        email: "Blue@gmail.com",
        Status:"active",
        transaction:"$7816.00",
    },
    {
        id: 9,
        username: 'El Capino LOP',
        avatar: "https://images.pexels.com/photos/1152994/pexels-photos-1152994.jpeg",
        email: "LOP@gmail.com",
        Status:"active",
        transaction:"$1456.00",
    },
    {
        id: 10,
        username: 'El Capino LOP',
        avatar: "https://images.pexels.com/photos/1152994/pexels-photos-1152994.jpeg",
        email: "LOP@gmail.com",
        Status:"active",
        transaction:"$1456.00",
    },
    {
        id: 11,
        username: 'El Capino LOP',
        avatar: "https://images.pexels.com/photos/1152994/pexels-photos-1152994.jpeg",
        email: "LOP@gmail.com",
        Status:"active",
        transaction:"$1456.00",
    },
];
  

 export const hadithRows = [
    {
        id: 1,
        hadith_number: 1,
        category:"Revelation",
        collection:"Sahih Bukhari",
        narrater:"Aisha",
        Status:"active",
    },
    {
        id: 2,
        hadith_number: 2,
        category:"Revelation",
        collection:"Sahih Bukhari",
        narrater:"Aisha",
        Status:"active",
    },
    {
        id: 3,
        hadith_number: 3,
        category:"Revelation",
        collection:"Sahih Bukhari",
        narrater:"Aisha",
        Status:"active",
    },
    {
        id: 4,
        hadith_number: 4,
        category:"Revelation",
        collection:"Sahih Bukhari",
        narrater:"Aisha",
        Status:"active",
    },
    {
        id: 5,
        hadith_number: 5,
        category:"Revelation",
        collection:"Sahih Bukhari",
        narrater:"Aisha",
        Status:"active",
    },
];
  
export const list = [
    {id: "test1", title:"test1"},
    {id: "test2", title:"test2"},
    {id: "test3", title:"test3"},
];

export const collectionData = [
    {id: 1, Collection:"Sahih bukhari", book:'book name 1'},
    {id: 2, Collection:"Nawawir Muslim", book:'book name 2'},
    {id: 3, Collection:"Hadiths collection 3", book:'book name 3'},
    {id: 4, Collection:"Hadiths collection 4", book:'book name 4'},
    {id: 5, Collection:"Hadiths collection 5", book:'book name 5'},
    {id: 6, Collection:"Hadiths collection 6", book:'book name 6'},
];
export const categoryData = [
    {id: 0, category_title:"Revelation", collection_title:"Sahir bukari"},
    {id: 1, category_title:"Power", collection_title:"Sahir bukari"},
    {id: 2, category_title:"Woman", collection_title:"Sahir bukari"},
    {id: 3, category_title:"Family", collection_title:"Nawawir"},
    {id: 4, category_title:"Religion", collection_title:"Nawawir"},
    {id: 5, category_title:"Revelation", collection_title:"Nawawir"},
    {id: 6, category_title:"Test", collection_title:"Nawawir"},
];
export const hadithsData = [
    {id: 1, category_title:"Revelation",col_id:1, collection_title:"Sahir bukari",narrator: 'Umar bin Al-Khattab',hadith_number:1,hadith_arab:'',hadith_eng:
    `I heard Allah's Apostle saying, "The reward of deeds depends upon the intentions and every person will get the reward according to what he has intended. So whoever emigrated for worldly benefits or for a woman to marry, his emigration was for what he emigrated for."`
    },
    {id: 2, category_title:"Revelation",col_id:1, collection_title:"Sahir bukari",narrator: 'Aisha (the mother of the faithful believers)',hadith_number:2,hadith_arab:'',hadith_eng:
    `Al-Harith bin Hisham asked Allah's
    Apostle "O Allah's Apostle! How is the Divine Inspiration revealed to you?"
    Allah's Apostle replied, "Sometimes it is (revealed) like the ringing of a bell,
    this form of Inspiration is the hardest of all and then this state passes ' off
    after I have grasped what is inspired. Sometimes the Angel comes in the
    form of a man and talks to me and I grasp whatever he says." 'Aisha added:
    Verily I saw the Prophet being inspired Divinely on a very cold day and
    noticed the Sweat dropping from his forehead (as the Inspiration was over).`
    },
    {id: 3, category_title:"Revelation",col_id:1, collection_title:"Sahir bukari",narrator: 'Aisha (the mother of the faithful believers)',hadith_number:3,hadith_arab:'',hadith_eng:
    `The commencement of the Divine
    Inspiration to Allah's Apostle was in the form of good dreams which came
    true like bright day light, and then the love of seclusion was bestowed upon
    him. He used to go in seclusion in the cave of Hira where he used to worship
    (Allah alone) continuously for many days before his desire to see his family.
    He used to take with him the journey food for the stay and then come back
    to (his wife) Khadija to take his food like-wise again till suddenly the Truth
    descended upon him while he was in the cave of Hira. The angel came to
    him and asked him to read. The Prophet replied, "I do not know how to
    read.
    
    The Prophet added, "The angel caught me (forcefully) and pressed me so
    hard that I could not bear it any more. He then released me and again asked
    me to read and I replied, 'I do not know how to read.' Thereupon he caught
    me again and pressed me a second time till I could not bear it any more. He
    then released me and again asked me to read but again I replied, 'I do not
    know how to read (or what shall I read)?' Thereupon he caught me for the
    third time and pressed me, and then released me and said, 'Read in the
    name of your Lord, who has created (all that exists) has created man from a
    clot. Read! And your Lord is the Most Generous." (96.1, 96.2, 96.3) Then
    Allah's Apostle returned with the Inspiration and with his heart beating
    severely. Then he went to Khadija bint Khuwailid and said, "Cover me! Cover
    me!" They covered him till his fear was over and after that he told her
    everything that had happened and said, "I fear that something may happen
    to me." Khadija replied, "Never! By Allah, Allah will never disgrace you. You
    keep good relations with your Kith and kin, help the poor and the destitute,
    serve your guests generously and assist the deserving calamity-afflicted
    ones."
    
    Khadija then accompanied him to her cousin Waraqa bin Naufal bin Asad bin
    'Abdul 'Uzza, who, during the PreIslamic Period became a Christian and used
    to write the writing with Hebrew letters. He would write from the Gospel in
    Hebrew as much as Allah wished him to write. He was an old man and had
    lost his eyesight. Khadija said to Waraqa, "Listen to the story of your
    nephew, O my cousin!" Waraqa asked, "O my nephew! What have you
    seen?" Allah's Apostle described whatever he had seen. Waraqa said, "This is
    the same one who keeps the secrets (angel Gabriel) whom Allah had sent to
    Moses. I wish I were young and could live up to the time when your people
    would turn you out." Allah's Apostle asked, "Will they drive me out?"
    Waraqa replied in the affirmative and said, "Anyone (man) who came with
    something similar to what you have brought was treated with hostility; and
    if I should remain alive till the day when you will be turned out then I would
    support you strongly." But after a few days Waraqa died and the Divine
    Inspiration was also paused for a while.
    
    English_Isnad Jabir bin 'Abdullah Al-Ansari while talking about the period of
    pause in revelation reporting the speech of the Prophet "While I was
    walking, all of a sudden I heard a voice from the sky. I looked up and saw the
    same angel who had visited me at the cave of Hira' sitting on a chair
    between the sky and the earth. I got afraid of him and came back home and
    said, 'Wrap me (in blankets).' And then Allah revealed the following Holy
    Verses (of Quran):
    
    'O you (i.e. Muhammad)! wrapped up in garments!' Arise and warn (the
    people against Allah's Punishment),... up to 'and desert the idols.' (74.1-5)
    After this the revelation started coming strongly, frequently and regularly."`
    },
    {id: 4, category_title:"Belief",col_id:1, collection_title:"Sahir bukari",narrator: 'Aisha (the mother of the faithful believers)',hadith_number:12,hadith_arab:'',hadith_eng:
    `Al-Harith bin Hisham asked Allah's
    Apostle "O Allah's Apostle! How is the Divine Inspiration revealed to you?"
    Allah's Apostle replied, "Sometimes it is (revealed) like the ringing of a bell,
    this form of Inspiration is the hardest of all and then this state passes ' off
    after I have grasped what is inspired. Sometimes the Angel comes in the
    form of a man and talks to me and I grasp whatever he says." 'Aisha added:
    Verily I saw the Prophet being inspired Divinely on a very cold day and
    noticed the Sweat dropping from his forehead (as the Inspiration was over).`
    },
    {id: 5, category_title:"Belief",col_id:1, collection_title:"Sahir bukari" ,narrator: 'Aisha (the mother of the faithful believers)',hadith_number:12,hadith_arab:'',hadith_eng:
    'testtest'},
    {id: 6, category_title:"Bathing (Ghusl)",col_id:1, collection_title:"Sahir bukari" ,narrator: 'Aisha (the mother of the faithful believers)',hadith_number:12,hadith_arab:'',hadith_eng:
    'fghjkl'},
    {id: 7, category_title:"Family",col_id:2, collection_title:"Nawawir"},
    {id: 8, category_title:"Religion",col_id:2, collection_title:"Nawawir"},
    {id: 9, category_title:"Revelation",col_id:2, collection_title:"Nawawir"},
    {id: 10, category_title:"Test",col_id:2, collection_title:"Nawawir"},
];
