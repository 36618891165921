import styles from "./styles.module.css";
import  "./pagination.css";
import { useState } from "react";

const Pagination = ({ page, total, limit, setPage, setLimit }) => {
	const [pageNumberLimit] = useState(5)
	const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
	const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)
	
	const totalPages = Math.ceil(total / limit);
	const onClick = (newPage) => {
		setPage(newPage + 1);
	};
	
	const handleNext = () => {
		setPage(page + 1)
		if(page + 1 > maxPageNumberLimit){
			setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
			setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
		}
	}
	const handlePrevious = () => {
		setPage(page - 1)
		if((page - 1) %  pageNumberLimit === 0){
			setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
			setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
		}
	}

	const handleFirst = () => {
		setPage(1)
	}
	const handleLast = () => {
		setPage(page + totalPages-1)
		if(page + totalPages-1 > maxPageNumberLimit){
			setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
			setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
		}
	}
	
	return (
		<div className={styles.container}>
			<button onClick={handlePrevious}  className={styles.pagination_button} disabled={page === 1 ? true : false} >Previous</button>
			{page > pageNumberLimit && <span className={styles.pagination_dots} onClick={handleFirst} >&hellip;</span>}
			{totalPages > 0 &&
				[...Array(totalPages)].map((val, index) => {
					if((index < maxPageNumberLimit ) && (index + 1 > minPageNumberLimit)){
						return(
							<button
							onClick={() => onClick(index)}
							className={
								page === index + 1
								? `${styles.page_btn} ${styles.active}`
								: styles.page_btn
							}
							key={index}
							>
								{index + 1}
							</button>
						)
					}else{
						return null;
					}
				})
			}
			{totalPages > page  && <span onClick={handleLast} className={styles.pagination_dots}>&hellip;</span>}
			<button onClick={handleNext} className={styles.pagination_button} disabled={page === totalPages ? true : false} >Next</button>
			<select className={styles.pagination_select} 
				onChange={e => setLimit(e.target.value)} name={limit} id={limit}>
					<option value={10}>10</option>
					<option value={20}>20</option>
					<option value={50}>50</option>
			</select>
		</div>
	);
};

export default Pagination;
