import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserAuth } from '../context/UserAuthContext'

const Login = () => {
  const [error, setError] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState('')
  const { logIn } = useUserAuth();

  const navigate = useNavigate();
  
  const handleLogin = async(e) => {
    e.preventDefault();
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if(password && email && regex.test(email) && password.length > 3){
      try {
       
        let User= await logIn(email, password);
        
        if (User.data){
          navigate("/dashboard");
        }
      } catch (err) {
          setError(true);
      }
  };
  }
  return (
    <div className='login'>
      <h1>Sign in</h1>
      <form onSubmit={handleLogin} className='loginForm' action="">
        <div className="loginInput">
          <label htmlFor=""></label>
          <input type="email"placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="loginInput">
          <label htmlFor=""></label>
          <input type="password" placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button type='submit' className="loginButton">Sign in</button>
        {error&&<span className="loginError">Error!!! Wrong email or password</span>}
      </form>
    </div>
  )
}

export default Login